<template>
	<main>
		<div class="container mt-5" id="home_header">
			<div class="row" id="home_nav">
	            <div v-for="menu in menus" class="col-6 col-md-3 mb-4" :key="menu.title">
	                <router-link
	                    active-class="menuActive"
	                    class="h-100 item-menu"
	                    :to="menu.to"
	                >
	                    <span><span>
	                    <i v-b-tooltip.right :title="$t(menu.title)">
	                        <font-awesome-icon :icon="menu.icon" />
	                    </i></span>{{ $t(menu.title) }}</span>
	                </router-link>
	            </div>
	        </div>
	        <div v-if="hasPaxAccess">
	        	<hr class="hr-pax">
	        	<HomePAX />
	        </div>
        </div>
	</main>
</template>

<script type="text/javascript">
	import Menus from '@/assets/js/menus'

	export default {
		name: 'HomeMenu',
		mixins: [Menus],
		data () {
			return {
				menus: [],
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				Menus.setInstance(this)
	            const menus = Menus.user(this.$route.name)
	            this.menus = menus.filter(menu => menu.home)
			},
		},

		computed: {
			hasPaxAccess() {
            	return this.$store.state.userAccess.hasPaxAccess
            },
		},

		components: {
			HomePAX: () => import('@/components/Pax/Home'),
		}
	}

</script>